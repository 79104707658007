import React, { useEffect, useState } from "react";
import partnerService from "../../../services/partners";
import { instanceToken } from "../../../utils/constant";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import DirectionsIcon from "@mui/icons-material/Directions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import * as XLSX from "xlsx";
import {
  Breadcrumbs,
  Button,
  TableBody,
  Avatar,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TablePagination,
  InputBase,
} from "@mui/material";
import { TableRowsRounded } from "@mui/icons-material";
import user2Service from "../../../services/user2Service";

export default function Users2() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchUsers(rowsPerPage, offset);
  }, [offset, rowsPerPage]);

  useEffect(() => {
    fetchSearchByName(search);
  }, [search]);

  const fetchSearchByName = async (search) => {
    try {
      const res = await user2Service.searchUser(instanceToken.token, search);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(document.getElementById("search-by-name").value);
  };

  const fetchUsers = async (limit, offset) => {
    try {
      const res = await user2Service.getAllUsers(
        instanceToken.token,
        limit,
        offset
      );

      parse(res.data);
      console.log("console ", res);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setUsers(data.demo_user);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportAllData = () => {
    const dataToExport = users.map((u) => ({
      UserID: u.userid,
      Name: u.name,
      User_Name: u.username,
      Gender: u.gender,
      Age: u.age,
      Phone: u.phone,
      Email: u.email,
      Address: u.address,
      Role: u.role,
      Otp_Exp_Time: u.otp_exp_time,
      One_Time_Password: u.one_time_password,
    }));

    const date = new Date();
    const boldCellStyle = { font: { bold: true } };
    const xls = XLSX.utils.json_to_sheet(dataToExport);
    xls["!cols"] = [{ wch: 20 }, { wch: 15 }, { wch: 20 }];
    xls["A1"].s = boldCellStyle;
    xls["B1"].s = boldCellStyle;
    xls["C1"].s = boldCellStyle;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, xls, "Receipt Data");
    const fileName = date.toISOString();
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  if (!users) {
    return <em>Loading</em>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 35,
        }}
      >
        <div role="presentation" style={{ marginBlockEnd: "10px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <span>User Level 2 </span>
          </Breadcrumbs>
        </div>
        <div>
          <Button variant="contained" onClick={exportAllData}>
            Export to Excel
          </Button>
        </div>
        {/* search */}
        <div>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 350,
            }}
          >
            {/* Search Box */}

            <InputBase
              id="search-by-name"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search By Username"
              type="search"
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              id="search-by-id"
              sx={{ p: "10px" }}
              aria-label="directions"
              value={search}
              onClick={handleSearch}
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        {/* <Button
          variant="contained"
          sx={{ my: 3 }}
          onClick={() => navigate("/createBanner")}
        >
          Create
        </Button> */}
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple-table">
          <TableHead>
            <TableRow className="thead">
              <TableCell>UserID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>UserName</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((f) => {
                return search.toLowerCase() === ""
                  ? f
                  : f.username.toLowerCase().includes(search);
              })
              .map((row) => (
                <TableRow
                  key={row.userid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.userid}
                  </TableCell>
                  {/* <TableCell>
                  <Avatar
                    alt="routine image"
                    src={`data:image/png;base64,${row.image_data}`}
                    width="56px"
                    height="56px"
                  />
                </TableCell> */}
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.gender}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      component={Link}
                      color="secondary"
                      to={`/users2/${row.userid}`}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
