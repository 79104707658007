import React, { useEffect, useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  TextField,
  FormControl,
  CardActions,
  CardMedia,
  Box,
  Typography,
  Card,
  CardContent,
  Breadcrumbs,
  Button,
  FormHelperText,
} from "@mui/material";
import bannerService from "../../../services/bannerService";
import { instanceToken } from "../../../utils/constant";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
export default function UpdateBanner({ homeAlert }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [banners, setBanners] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [oldImageName, setOldImageName] = useState(null);

  useEffect(() => {
    if (!banners) {
      fetchBanners();
    }
  }, []);

  useEffect(() => {
    if (banners) {
      setNameEng(banners?.name_en);
      setNameMM(banners?.name_mm);
      setLink(banners?.link);
      setImage(banners.image_data);
      setPreview(`data:image/jpeg;base64,${banners?.image_data}`);

      if (banners.image_data) {
        setOldImageName(
          banners.image_data.substring(
            banners.image_data.lastIndexOf("/") + 1,
            banners.image_data.lenght
          )
        );
      }
    }
  }, [banners]);

  const fetchBanners = async () => {
    try {
      // const res = await axios.get(
      //   `https://cblife.axra.app/dashboard/api/bannerss/${id}`
      // );

      const res = await bannerService.getOneBanner(instanceToken.token, id);
      setBanners(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeNameMM = (e) => {
    setNameMM(e.target.value);
  };
  const changeNameEng = (e) => {
    setNameEng(e.target.value);
  };
  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_data: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrors({
          ...errors,
          image_data: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImage(img);
    }
  };

  const convertBase64 = (img) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(img);

      fileReader.onloadend = () => resolve(fileReader.result);
      fileReader.onerror = (err) => reject(err);
    });
  };
  const formData = new FormData();
  const handleUpdate = async (e) => {
    e.preventDefault();

    formData.append("name_en", nameEng);
    formData.append("name_mm", nameMM);
    formData.append("link", link);
    formData.append("image_data", image);

    setErrors({});
    let err = {};
    formData.forEach((value, index) => {
      if (value === "") {
        err[index] = `${index} field is required`;
      }
    });
    console.log("error ", err);
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }

    try {
      setLoading(true);

      const response = await bannerService.putBanner(
        instanceToken.token,
        id,
        formData
      );
      navigate("/banners");
      setBanners(response.data);
      homeAlert("News banners have been updated.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/banners">Banners</Link>
          <span>Update Banners</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0 }}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                height="320"
                image={preview}
                sx={{ my: 2 }}
              />

              <FormControl sx={{ my: 2 }}>
                <Typography
                  sx={{ fontSize: "12px", mb: 1, textAlign: "center" }}
                >
                  Rendered size must be 1920 * 1200 px and Aspect ratio must be
                  16: 10
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>{errors["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                ml: "2rem",
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  value={nameEng}
                  onChange={changeNameEng}
                  error={errors["name_en"]}
                  helperText={errors["name_en"]}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="name_mm"
                  value={nameMM}
                  onChange={changeNameMM}
                  error={errors["name_mm"]}
                  helperText={errors["name_mm"]}
                />
              </FormControl>

              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  value={link}
                  onChange={changeLink}
                  error={errors["link"]}
                  helperText={errors["link"]}
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
