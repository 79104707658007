import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  CardMedia,
} from "@mui/material";
import uploadService from "../../../services/upload";

import { instanceToken } from "../../../utils/constant";
import axios from "axios";
import user2Service from "../../../services/user2Service";
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const User2 = ({ homeAlert }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState(null);
  const [openR, setOpenR] = useState(false);

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    try {
      const res = await user2Service.getOneUser(instanceToken.token, id);
      setUser(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!user) {
    return <em>Loading...</em>;
  }

  const handleOpenR = () => setOpenR(true);
  const handleCloseR = () => setOpenR(false);
  const handleRemove = async () => {
    try {
      await user2Service.deleteUser(instanceToken.token, id);
      homeAlert("User post have been removed.", false);
      navigate("/users2");
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/users2">
            User
          </Link>
          <Typography color="text.primary">User (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Card
          sx={{
            minWidth: "80vw",
            minHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "2fr 3fr",
          }}
        >
          <Box
            sx={{
              maxWidth: "40vw",
              justifyContent: "center",
              ml: 33,
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            {/* <CardMedia
              component="img"
              height="320"
              image={`data:image/png;base64,${partner.image_data}`}
            /> */}
          </Box>
          <Box
            sx={{
              ml: 2,
              p: 2,
              // bgcolor: "#f7f7f5",
              borderRadius: 2,
            }}
          >
            {/* User ID */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  UserID:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.userid}
                </Typography>
              </Grid>
            </Grid>
            {/* Address */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Address:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.address}
                </Typography>
              </Grid>
            </Grid>
            {/* Age */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Age:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.age}
                </Typography>
              </Grid>
            </Grid>
            {/* Email */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.email}
                </Typography>
              </Grid>
            </Grid>
            {/* otp_exp_time */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Otp_exp_time:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.otp_exp_time}
                </Typography>
              </Grid>
            </Grid>
            {/* Gender */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Gender:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.gender}
                </Typography>
              </Grid>
            </Grid>
            {/* Name */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.name}
                </Typography>
              </Grid>
            </Grid>
            {/* One time password */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  One Time password:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.one_time_password}
                </Typography>
              </Grid>
            </Grid>
            {/* password */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Password:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.password}
                </Typography>
              </Grid>
            </Grid>
            {/* Phone Number */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Phone Number:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.phone_number}
                </Typography>
              </Grid>
            </Grid>
            {/* Role */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Role:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.role}
                </Typography>
              </Grid>
            </Grid>
            {/* User name */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Username:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {user.username}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
            <Button
              sx={{ m: "1rem" }}
              variant="contained"
              color="error"
              onClick={handleOpenR}
            >
              Remove
            </Button>
            {/* <Button
              size="small"
              component={Link}
              to={`/updatePartner/${partner.id}`}
            >
              Edit
            </Button> */}
          </CardActions>
        </Card>
      </Container>
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default User2;
